import React from "react";
import { ButtonStyle } from "./style";

const Button = ({ onClick, type, text }) => {
  return (
    <ButtonStyle type={type} onClick={onClick}>
      {text ? "Loading" : "Download Report"}
    </ButtonStyle>
  );
};

export default Button;
