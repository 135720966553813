import styled from "styled-components";

export const ButtonStyle = styled.button`
  background-color: #c43836;
  cursor: pointer;
  width: 100%;
  height: 60px;
  border: none;
  margin-top: 70px;

  text-decoration: none;
  color: #ffffff;
  font-size: 16px;
  font-weight: 500;
  text-align: center;
`;
