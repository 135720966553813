import styled from "styled-components";

export const BluePartitionStyle = styled.div`
  height: 100%;
  background-color: #235582;
  color: #ffffff;
  width: 35%;
  padding-bottom: 50px;
  position: fixed;
  right: 0;
  padding-top: 5%;

  @media (max-width: 1080px) {
    position: static;
    flex-basis: 100%;
    width: 100%;
    padding-top: 100px;
  }
  .content {
    width: 70%;
    margin: auto 15%;

    @media (max-width: 1080px) {
      width: 90%;
      margin: auto 5%;
    }
  }
  h1 {
    font-size: 30px;
    font-weight: bold;
  }

  p {
    font-size: 18px;
    line-height: 142.3%;
    width: 100%;
  }

  .disclaimer {
    font-size: 15px;
    text-align: center;
    color: #ffffff;
    opacity: 0.5;
  }
`;
