import React, { useState } from "react";
import { FormStyle } from "./style";
import Button from "../Button";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";


const GOOGLE_DRIVE_PDF_LINK =
  "https://drive.google.com/file/d/1UB4fBWYINFSKwSIsccX2s9RzHS1aGHBi/view?usp=sharing";

const Form = () => {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    organization: "",
    position: "",
    email: "",
  });
  const [sending, setSending] = useState(false);
  const onFormChange = (e) => {
    e.preventDefault();
    setFormData((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const formSubmit = () => {
    setSending(true);
    fetch(
      `https://script.google.com/macros/s/AKfycby616a46YarWeaBigFZkA0HVmtVu4ElFDXCp8sonCpS6xvBqcN9mGSo/exec?First name=${formData.firstName}&Last name=${formData.lastName}&Position=${formData.position}&Organization=${formData.organization}&Email address=${formData.email}`,
      {
        method: "GET",
      }
    )
      .then((data) => {
        setSending(false);
        toast.success("Your details uploaded successfully Downloading PDF in a bit", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setTimeout(() => window.open(GOOGLE_DRIVE_PDF_LINK, "_blank"),5000);
        setFormData({
          firstName: "",
          lastName: "",
          organization: "",
          position: "",
          email: "",
        });
      })
      .catch((err) => {
        toast.error("Errors Saving Details. Kindly try again", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setSending(false)
      });
  };

  return (
    <FormStyle>
      <form name="download" data-netlify="true" onSubmit={formSubmit}>
        <div className="break-input">
          <input
            type="text"
            name="firstName"
            placeholder="First name"
            id="right"
            onChange={onFormChange}
          />
          <input
            type="text"
            name="lastName"
            placeholder="Last name"
            id="left"
            onChange={onFormChange}
          />
        </div>
        <input
          type="text"
          name="organization"
          placeholder="Organization"
          onChange={onFormChange}
        />
        <input
          type="text"
          name="position"
          placeholder="Position e.g Founder"
          onChange={onFormChange}
        />

        <input
          type="email"
          name="email"
          placeholder="Email address"
          onChange={onFormChange}
        />

        <Button
          type="button"
          text={sending}
          onClick={() => formSubmit()}
          disabled={!sending}
        />
      </form>
        <ToastContainer />
    </FormStyle>
  );
};

export default Form;
